<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="$emit('close')"
  >
    <template slot="title">
      Request Type Form
    </template>
    <template slot="body">
      <form-text
        v-model="form.title"
        label="Title"
      />
      <form-textarea
        v-model="form.description"
        label="Description"
      />
      <form-text
        v-model="form.creation_response"
        label="Creation Response"
      />

      <div class="alert alert-info">
        <small>In case there is no <b>Programme Status</b> selected. </small>
        <b>All programmes will see this request type</b>
      </div>
      <ProgrammeStatusesSelect
        v-model="form.programme_statuses"
        multiple
        label="Programme Status"
        class="flex-1"
      />

      <LetterTemplatesSelect
        v-model="form.letter_template_id"
        null-option="None"
        label="Letter Template"
        @change="onChangeLetterTemplate"
      />

      <AuthoritiesSelect
        v-if="form.letter_template_id && form.letter_template_id !== 'null'"
        v-model="form.authority_id"
      />
      <RequestTypeCategoriesSelect
        v-model="form.category_id"
        label="Category"
      />
      <md-switch v-model="form.requires_collection">
        Requires Collection
      </md-switch>
      <md-switch v-model="form.is_student_request">
        Request to Student (Usable from notification menu, not an open request)
      </md-switch>

      <div
        v-for="(input, index) of form.attributes"
        :key="index"
        class="attribute-item"
      >
        <div class="md-layout">
          <div class="md-layout-item pt-2">
            <b>INPUT #{{ index+1 }}</b>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item"
            :class="input.template && input.template.can_update_student_data
              ? 'md-size-70' : ' md-size-100'"
          >
            <form-select
              v-model="input.template.request_input_template_id"
              label="Input Template"
              :options="input_templates"
              @change="onChangeInputTemplate($event, input)"
            />
          </div>
          <div
            v-if="input.template && input.template.can_update_student_data"
            class="md-layout-item md-size-30"
          >
            <md-switch
              :key="input.template.id"
              v-model="input.update_student_data"
            >
              Update Student Data
            </md-switch>
          </div>
          <div class="md-layout-item md-size-25">
            <form-select
              v-model="input.input_type"
              label="Input type"
              :options="inputTypes"
              :disabled="input.template.request_input_template_id"
              @change="onChangeInputType(input)"
            />
          </div>
          <div class="md-layout-item md-size-25">
            <form-text
              v-model="input.title"
              :disabled="input.template.request_input_template_id || input.input_type === 'file'"
              label="Input Name"
              @input="onInputTitleChange($event, input)"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <form-text
              v-model="input.placeholder"
              class="flex-1"
              label="Input Placeholder"
              :disabled="input.template.request_input_template_id"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <form-text
              v-model="input.helper"
              class="flex-1"
              label="Input Helper Text"
            />
          </div>
          <div
            v-if="input.options && input.options.length"
            class="md-layout-item md-size-100"
          >
            <div
              v-for="(option, optIndex) of input.options"
              :key="optIndex"
              class="md-layout option-item"
            >
              <div class="md-layout-item md-size-40 pl-0">
                <form-text
                  v-model="option.id"
                  class="flex-1"
                  label="Option Value"
                  :disabled="input.template.request_input_template_id"
                />
              </div>
              <div
                class="md-layout-item"
                style="flex: 20"
              >
                <form-text
                  v-model="option.name"
                  class="flex-1"
                  label="Option Placeholder"
                  :disabled="input.template.request_input_template_id"
                />
              </div>
              <div class="md-layout-item pr-0">
                <md-button
                  class="md-just-icon md-danger md-block"
                  :disabled="input.template.request_input_template_id"
                  @click="deleteInputOption(input, optIndex)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </div>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              v-if="input.input_type === 'select'"
              class="md-block md-sm md-primary"
              :disabled="input.template.request_input_template_id"
              @click="addInputOption(input)"
            >
              Add Option
            </md-button>
            <md-button
              class="md-block md-sm md-danger"
              @click="deleteInput(index)"
            >
              Delete Input
            </md-button>
          </div>
        </div>
      </div>

      <md-button
        class="md-block md-sm md-success mt-4"
        @click="addInput"
      >
        Add Input
      </md-button>
    </template>
    <template slot="footer">
      <md-button
        class="md-success"
        :disabled="loading"
        @click="saveForm"
      >
        <div v-if="!loading">
          Save
        </div>
        <div v-else>
          Loading
        </div>
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText, FormTextarea, FormSelect } from '@/components/Inputs';
import {
  LetterTemplatesSelect,
  AuthoritiesSelect,
} from '@/components/Inputs/selects';
import RequestTypeCategoriesSelect from '@/components/Inputs/selects/RequestTypeCategoriesSelect.vue';
import ProgrammeStatusesSelect from '@/components/Inputs/selects/ProgrammeStatusesSelect.vue';

export default {
  components: {
    FormText,
    FormTextarea,
    FormSelect,
    LetterTemplatesSelect,
    RequestTypeCategoriesSelect,
    AuthoritiesSelect,
    ProgrammeStatusesSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      title: null,
      description: null,
      creation_response: null,
      letter_template_id: null,
      authority_id: null,
      requires_collection: false,
      is_student_request: false,
      attributes: [],
      prorgamme_statuses: [],
    },
    input_templates: [],
    loading: false,
  }),
  computed: {
    inputTypes() {
      const types = [
        { id: 'text', name: 'Text' },
        { id: 'numeric', name: 'Number' },
        { id: 'select', name: 'Select' },
        { id: 'country_select', name: 'Country Select' },
        { id: 'date', name: 'Date' },
        { id: 'textarea', name: 'Textarea' },
      ];

      const filesCount = this.form.attributes
        .reduce((a, b) => a + (b.input_type === 'file' ? 1 : 0), 0);

      if (filesCount < 5) {
        types.push({ id: 'file', name: 'Image or PDF File' });
      }

      return types;
    },
  },
  mounted() {
    if (this.item) {
      this.form = { ...this.item };
    }
    this.getInputTemplates();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getInputTemplates() {
      this.request('request_input_templates', 'get', null, ({ data }) => {
        this.input_templates = data.map((x) => {
          x.id = x.request_input_template_id;
          x.input_name = x.name;
          x.name = x.placeholder;
          return x;
        });
      });
    },
    onChangeInputTemplate(template, input) {
      input.template = template;
      input.input_type = template.type;
      input.title = template.input_name;
      input.placeholder = template.placeholder;
      input.options = template.options;
      input.update_student_data = false;

      if (template.input_group_id) {
        const group = this.input_templates.filter(
          (x) => x.input_group_id === template.input_group_id
          && x.request_input_template_id !== template.request_input_template_id,
        );

        group.forEach((x) => {
          this.form.attributes.push({
            template: x,
            update_student_data: false,
            input_type: x.type,
            title: x.input_name,
            placeholder: x.placeholder,
            helper: null,
            options: x.options,
          });
        });
      }
    },
    addInput() {
      this.form.attributes.push({
        template: {},
        update_student_data: false,
        input_type: 'text',
        title: null,
        placeholder: null,
        helper: null,
        options: [],
      });
    },
    deleteInput(index) {
      if (this.form.attributes[index].template?.input_group_id) {
        const { template } = this.form.attributes[index];
        const others = this.form.attributes
          .filter((x) => x.template.input_group_id === template.input_group_id);

        others.forEach((x) => {
          this.form.attributes
            .splice(this.form.attributes.findIndex((y) => y.title === x.title), 1);
        });
      }

      this.form.attributes.splice(index, 1);
    },
    addInputOption(input) {
      input.options.push({ id: null, name: null });
    },
    deleteInputOption(input, optIndex) {
      input.options.splice(optIndex, 1);
    },
    onChangeInputType(input) {
      if (input.input_type !== 'select') {
        input.options = [];
      }

      if (input.input_type === 'file') {
        input.title = 'file';
      }
    },
    onChangeLetterTemplate(e) {
      if (!e) {
        this.form.authority_id = null;
        this.form.letter_template_id = null;
      }
    },
    onInputTitleChange(e, input) {
      input.title = e.toLowerCase().split(' ').join('_');
    },
    saveForm() {
      if (this.form.letter_template_id && this.form.letter_template_id !== null) {
        if (!this.form.authority_id) {
          this.fireError('Please select a Signing Authority');
          return;
        }
      }

      this.loading = true;

      if (this.form.request_type_id) {
        this.request(`request_types/${this.form.request_type_id}`, 'put', { ...this.form }, () => {
          this.fireSuccess('Request Type updated succesfully!');
          this.$emit('saved');
        }, () => {
          this.loading = false;
        });
      } else {
        this.request('request_types', 'post', { ...this.form }, () => {
          this.fireSuccess('Request Type created succesfully!');
          this.$emit('saved');
        }, () => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style>
.attribute-item {
  border: 1px solid #ccc;
  border-radius: 0.35em;
  padding: 0.25em 0;
  margin-top: .5em;
}
.option-item {
  border-top: 1px solid #ccc;
  margin-top: .5em;
  padding-top: .5em;
}

.description {
  background: #ccc;
  padding: 1.5em;
  border-radius: .5em;
  -webkit-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.15);
  color: #312f2f;
}
</style>
